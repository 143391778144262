<template>
  <div class="bg-white px-1 rounded-lg">
    <span class="font-weight-bolder font-medium-5 d-block">
      {{ $t('Billing') }}
    </span>
    <b-row class="mt-1 pb-1 ml-0">
      <b-col cols="2">
        <span class="font-weight-bolder">
          {{ $t('DBA') }}
        </span>
      </b-col>
      <b-col cols="10">
        <span>
          {{ $t('John Doe Films, Inc.') }}
        </span>
      </b-col>
      <b-col cols="2">
        <span class="font-weight-bolder">
          {{ $t('Contact') }}
        </span>
      </b-col>
      <b-col cols="10">
        <span>
          {{ $t('Trisha Ziff') }}
        </span>
      </b-col>
      <b-col cols="2">
        <span class="font-weight-bolder">
          {{ $t('Phone') }}
        </span>
      </b-col>
      <b-col cols="10">
        <span>
          {{ $t('+1(858)555-0123') }}
        </span>
      </b-col>
      <b-col cols="2">
        <span class="font-weight-bolder">
          {{ $t('Email') }}
        </span>
      </b-col>
      <b-col cols="10">
        <span>
          {{ $t('trish@johndoefilms.com') }}
        </span>
      </b-col>
      <b-col cols="2">
        <span class="font-weight-bolder">
          {{ $t('Address') }}
        </span>
      </b-col>
      <b-col cols="10">
        <span>
          {{ $t('8939 Caminito Verano, La Jolla, CA 92037. USA') }}
        </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'Billing',
  components: {
    BRow,
    BCol,
  },
}
</script>

<style scoped lang="scss">

</style>
