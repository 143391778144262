<template>
  <div>
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-1">
        {{ $t('Fulfillment for Order RO-1024') }}
        <!--        {{ id }}-->
      </h3>
    </portal>
    <skeleton v-if="loading" />
    <dispatch-order-form
      v-else
      ref="dispatch-order-form"
      @refetch="refetch"
    />
  </div>
</template>

<script>
import Skeleton from '@/views/main/warehouse/view/pick/rental-sales/components/Skeleton.vue'
import DispatchOrderForm from '@/views/main/warehouse/view/dispatch/components/DispatchOrderForm.vue'

export default {
  components: {
    DispatchOrderForm,
    Skeleton,
  },
  methods: {
    refetch() {
      // this.loading = true
      // this.$store.dispatch(`${this.MODULE_NAME}/getRentalSalesOrders`, this.$route.params.id).then(res => {
      //   const { data } = res.data
      //   this.$store.commit(`${this.MODULE_NAME}/SET`, data)
      // }).catch(err => {
      //   this.error = true
      //   this.errorNotification(this, err)
      // }).finally(() => {
      //   this.loading = false
      // })
    },

  },

}
</script>
