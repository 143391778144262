<template>
  <div class="bg-white px-1 rounded-lg">
    <span class="font-weight-bolder font-medium-5 d-block">
      {{ $t('Dispatch') }}
    </span>
    <b-row class="mt-1 pb-1 ml-0">
      <b-col cols="2">
        <span class="font-weight-bolder">
          {{ $t('Contact') }}
        </span>
      </b-col>
      <b-col cols="10">
        <span>
          {{ $t('John Smith') }}
        </span>
      </b-col>
      <b-col cols="2">
        <span class="font-weight-bolder">
          {{ $t('Phone') }}
        </span>
      </b-col>
      <b-col cols="10">
        <span>
          {{ $t('+1(858)555-0123') }}
        </span>
      </b-col>
      <b-col cols="2">
        <span class="font-weight-bolder">
          {{ $t('Email') }}
        </span>
      </b-col>
      <b-col cols="10">
        <span>
          {{ $t('trish@johndoefilms.com') }}
        </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'Dispatch',
  components: {
    BRow,
    BCol,
  },
}
</script>

<style scoped lang="scss">

</style>
