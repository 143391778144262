<template>
  <div class="bg-white px-1 rounded-lg">
    <span class="font-weight-bolder font-medium-5 d-block">
      {{ $t('Insurance Information') }}
    </span>
    <b-row class="mt-1 pb-1 ml-0">
      <b-col cols="6">
        <b-row>
          <b-col cols="2">
            <span class="font-weight-bolder">
              {{ $t('Policy No.') }}
            </span>
          </b-col>
          <b-col cols="10">
            <span>
              {{ $t('State Farm - AB7733267') }}
            </span>
          </b-col>
          <b-col cols="2">
            <span class="font-weight-bolder">
              {{ $t('For Up To') }}
            </span>
          </b-col>
          <b-col cols="10">
            <span>
              {{ $t('$50,000.00') }}
            </span>
          </b-col>
          <b-col cols="2">
            <span class="font-weight-bolder">
              {{ $t('Deductible') }}
            </span>
          </b-col>
          <b-col cols="10">
            <span>
              {{ $t('$600.00') }}
            </span>
          </b-col>
        </b-row>
      </b-col>
      <b-col cols="6">
        <b-row>
          <b-col
            cols="2"
            class="ml-2"
          >
            <span class="font-weight-bolder">
              {{ $t('From') }}
            </span>
          </b-col>
          <b-col cols="9">
            <span>
              {{ $t('December 21, 2021') }}
            </span>
          </b-col>
          <b-col
            cols="2"
            class="ml-2"
          >
            <span class="font-weight-bolder">
              {{ $t('To') }}
            </span>
          </b-col>
          <b-col cols="9">
            <span>
              {{ $t('December 20, 2022') }}
            </span>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'InsuranceInformation',
  components: {
    BRow,
    BCol,
  },
}
</script>

<style scoped lang="scss">

</style>
