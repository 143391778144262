<template>
  <div class="bg-white px-1 rounded-lg">
    <span class="font-weight-bolder font-medium-5 d-block">
      {{ $t('Return') }}
    </span>
    <b-row class="mt-1 pb-1 ml-0 mb-2">
      <b-col
        cols="2"
        class="mb-1"
      >
        <span class="font-weight-bolder">
          {{ $t('Date') }}
        </span>
      </b-col>
      <b-col cols="10">
        <feather-icon icon="LCalendarsIcon" />
        <span class="font-weight-bolder">
          {{ $t('Sept. 20, 2022, 09:30 am') }}
        </span>
      </b-col>
      <b-col
        cols="2"
        class="mb-1"
      >
        <span class="font-weight-bolder">
          {{ $t('Method') }}
        </span>
      </b-col>
      <b-col cols="10">
        <span>
          {{ $t('Warehouse Pick up') }}
        </span>
      </b-col>
      <b-col
        cols="2"
        class="mb-1"
      >
        <span class="font-weight-bolder">
          {{ $t('Address') }}
        </span>
      </b-col>
      <b-col cols="10">
        <span class="font-weight-bolder">
          {{ $t('Brooklyn Warehouse') }}
        </span>
      </b-col>
      <b-col cols="2" />
      <b-col cols="10">
        <span>
          {{ $t('154 Bogart Street Brooklyn NY 11206 - Drive In Loading Area') }}
        </span>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { BCol, BRow } from 'bootstrap-vue'

export default {
  name: 'ReturnFulfillment',
  components: {
    BRow,
    BCol,
  },
}
</script>

<style scoped lang="scss">

</style>
