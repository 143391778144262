<template>
  <div
    class="bg-white mb-1"
    style="border-radius: 5px; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);"
  >
    <b-row class="mt-1 pb-1 ml-0">
      <b-col cols="6">
        <span class="font-weight-bolder font-medium-5 d-block pt-1">
          {{ $t('Authorized Dispatch Contacts') }}
        </span>
        <div>
          <b-form-group
            v-slot="{ ariaDescribedby }"
          >
            <b-form-radio
              v-for="radio in preferredContact"
              :key="radio.value"
              class="custom-radio-group-input"
              :aria-describedby="ariaDescribedby"
              :name="radio.value"
              :value="radio.value"
            >
              {{ $t(radio.title) }}
            </b-form-radio>
          </b-form-group>
        </div>
      </b-col>
      <b-col cols="6">
        <span class="font-weight-bolder font-medium-5 d-block pt-1">
          {{ $t('ID (Front)') }}
        </span>
        <img
          width="547px"
          height="343px"
          :src="IdCardImage"
          alt="aw"
        >
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BCol, BFormGroup, BFormRadio, BRow,
} from 'bootstrap-vue'
import config from '@/views/main/warehouse/view/dispatch/dispatchConfig'
import IdCardImage from '@/views/main/warehouse/view/dispatch/components/authorized-dispatch-contacts/ID-custom.jpg'

export default {
  name: 'AuthorizedDispatchContacts',
  components: {
    BFormRadio,
    BFormGroup,
    BRow,
    BCol,
  },
  setup() {
    const { preferredContact } = config()
    return {
      preferredContact,
      IdCardImage,
    }
  },
}
</script>

<style scoped lang="scss">
.custom-radio-group-input {
  margin-top: 25px !important;
}
</style>
