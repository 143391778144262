<template>
  <div
    class="bg-white mb-1"
    style="border-radius: 5px; padding: 12px; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);"
  >
    <b-button
      variant="secondary"
      class="secondary font-weight-bolder"
      @click="submit"
    >
      {{ $t('By Container') }}
    </b-button>
    <b-button
      variant="secondary"
      class="bg-D9D9D9 ml-1"
      @click="submit"
    >
      {{ $t('As Ordered') }}
    </b-button>
    <h3 class="font-weight-bolder text-body mt-1">
      {{ $t('Rental Items') }}
    </h3>
    <b-table-simple>
      <b-thead>
        <b-tr>
          <b-td class="px-1">
            {{ $t('State') }}
          </b-td>
          <b-td class="px-1">
            {{ $t('ID') }}
          </b-td>
          <b-td class="px-1">
            {{ $t('Name') }}
          </b-td>
        </b-tr>
      </b-thead>
      <b-tbody />
    </b-table-simple>
  </div>
</template>
<script>

import {
  BButton, BTableSimple, BTd, BThead, BTr,
} from 'bootstrap-vue'

export default {
  components: {
    BTd,
    BThead,
    BTr,
    BButton,
    BTableSimple,
  },
  methods: {
    submit() {
      return 1
    },
  },
}
</script>
<style scoped lang="scss">
.bg-D9D9D9 {
  background-color: #d9d9d9 !important;
  color: #646464 !important;
}
</style>
