<template>
  <div>
    <quote-status-bar
      :quote-status-bar="quoteStatusBar"
      class="mb-1"
    />
    <order-information-form />
    <fulfillment-form />
    <authorized-dispatch-contacts />
    <rental-items />
    <totals-form />
  </div>
</template>

<script>
import QuoteStatusBar from '@/views/main/orders/components/use-as-global/QuoteStatusBar.vue'
import mainConfig from '@/views/main/warehouse/config'
import orderInformationForm
from '@/views/main/warehouse/view/dispatch/components/order-information/OrderInformationForm.vue'
import FulfillmentForm from '@/views/main/warehouse/view/dispatch/components/fulfillment/FulfillmentForm.vue'
import AuthorizedDispatchContacts
from '@/views/main/warehouse/view/dispatch/components/authorized-dispatch-contacts/AuthorizedDispatchContacts.vue'
import RentalItems from '@/views/main/warehouse/view/dispatch/components/rental-items/RentalItems.vue'
import TotalsForm from '@/views/main/warehouse/view/dispatch/components/totals/TotalsForm.vue'

export default {
  components: {
    TotalsForm,
    RentalItems,
    AuthorizedDispatchContacts,
    FulfillmentForm,
    orderInformationForm,
    QuoteStatusBar,
  },

  setup() {
    const { quoteStatusBar } = mainConfig()

    return {
      quoteStatusBar,
      orderInformationForm,
    }
  },

}
</script>
