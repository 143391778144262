import { TEXTAREA_INPUT } from '@/views/components/DynamicForm/constants'

export default function config() {
  const TOTALS_TOTAL_PRICE_TITLE = 'TOTAL'
  const TOTALS_TOTAL_AMOUNT_PAID_TITLE = 'AMOUNT PAID'
  const TOTALS_TOTAL_AMOUNT_DUE_TITLE = 'AMOUNT DUE'
  const TOTALS_ITEMS_TOTAL_PRICE_TITLE = 'Rentals'
  const TOTALS_TAXES_TOTAL_PRICE_TITLE = 'Taxes'
  const TOTALS_DISCOUNT_TOTAL_PRICE_TITLE = 'Discounts'
  const TOTALS_OTHER_CHARGES_TOTAL_PRICE_TITLE = 'Sales and Other Charges'

  const transferScheduleFields = {
    transfer_order_comments: {
      type: TEXTAREA_INPUT,
      label: 'Order notes',
      placeholder: 'None found...',
      options: {
        rows: '10',
      },
    },
  }

  return {
    TOTALS_TOTAL_PRICE_TITLE,
    TOTALS_TOTAL_AMOUNT_PAID_TITLE,
    TOTALS_TOTAL_AMOUNT_DUE_TITLE,
    TOTALS_ITEMS_TOTAL_PRICE_TITLE,
    TOTALS_TAXES_TOTAL_PRICE_TITLE,
    TOTALS_DISCOUNT_TOTAL_PRICE_TITLE,
    TOTALS_OTHER_CHARGES_TOTAL_PRICE_TITLE,
    transferScheduleFields,
  }
}
