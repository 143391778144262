<template>

  <div
    class="bg-white mb-1"
    style="border-radius: 5px; padding: 12px; box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.05);"
  >
    <h3 class="font-weight-bolder text-body">
      {{ $t('Totals') }}
    </h3>
    <b-row class="mt-2">
      <b-col cols="6">
        <component
          :is="transferScheduleFields['transfer_order_comments'].type"
          v-bind="getProps('transfer_order_comments', transferScheduleFields)"
        />
      </b-col>
      <b-col cols="6">
        <div class="form-wrapper">
          <b-list-group class="totals-form-list-group">
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_ITEMS_TOTAL_PRICE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView('152') }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_OTHER_CHARGES_TOTAL_PRICE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView('258') }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_DISCOUNT_TOTAL_PRICE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView('15') }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_TAXES_TOTAL_PRICE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView('552') }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item font-weight-bolder">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_TOTAL_PRICE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView('899') }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item font-weight-bolder">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_TOTAL_AMOUNT_PAID_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView('905') }}
                </b-col>
              </b-row>
            </b-list-group-item>
            <b-list-group-item class="totals-form-list-group-item font-weight-bolder">
              <b-row>
                <b-col cols="6">
                  {{ TOTALS_TOTAL_AMOUNT_DUE_TITLE }}
                </b-col>
                <b-col
                  cols="6"
                  class="text-right"
                >
                  {{ formatNumberToCurrencyView('908') }}
                </b-col>
              </b-row>
            </b-list-group-item>
          </b-list-group>
        </div>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import {
  BCol, BListGroup, BListGroupItem, BRow,
} from 'bootstrap-vue'
import config from '@/views/main/warehouse/view/dispatch/components/totals/totalsConfig'
import { getProps, formatNumberToCurrencyView } from '@core/utils/utils'

export default {
  name: 'TotalsForm',
  components: {
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
  },
  methods: { getProps },
  setup() {
    const {
      transferScheduleFields,
      TOTALS_TOTAL_PRICE_TITLE,
      TOTALS_TOTAL_AMOUNT_PAID_TITLE,
      TOTALS_TOTAL_AMOUNT_DUE_TITLE,
      TOTALS_ITEMS_TOTAL_PRICE_TITLE,
      TOTALS_TAXES_TOTAL_PRICE_TITLE,
      TOTALS_DISCOUNT_TOTAL_PRICE_TITLE,
      TOTALS_OTHER_CHARGES_TOTAL_PRICE_TITLE,
    } = config()

    return {
      transferScheduleFields,
      TOTALS_TOTAL_PRICE_TITLE,
      TOTALS_TOTAL_AMOUNT_PAID_TITLE,
      TOTALS_TOTAL_AMOUNT_DUE_TITLE,
      TOTALS_ITEMS_TOTAL_PRICE_TITLE,
      TOTALS_TAXES_TOTAL_PRICE_TITLE,
      TOTALS_DISCOUNT_TOTAL_PRICE_TITLE,
      TOTALS_OTHER_CHARGES_TOTAL_PRICE_TITLE,
      formatNumberToCurrencyView,
    }
  },
}
</script>
