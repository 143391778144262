export default function config() {
  const ACCESS_ABILITY_SETTINGS_ORDERS = { action: 'change', subject: 'Catalog' }
  const MODULE_NAME = 'pick-rental-sales-order'

  const ORDER_INFORMATION_TITLE = 'Order Information'
  const FULFILLMENT_TITLE = 'Fulfillment'

  const preferredContact = [
    {
      value: 'one',
      title: 'Michael Motorist',
    },
    {
      value: 'two',
      title: 'Michelle Zif',
    },
    {
      value: 'three',
      title: 'Jane Doe',
    },
  ]

  const filterTabsList = [
    {
      title: 'By Container',
      query: 'by-container',
      tabNumber: 2,
      pageTitle: 'List By Container',
    },
    {
      title: 'As ordered',
      query: 'as-ordered',
      tabNumber: 3,
      pageTitle: 'List by as Ordered',
    },
  ]

  return {
    ACCESS_ABILITY_SETTINGS_ORDERS,
    MODULE_NAME,
    ORDER_INFORMATION_TITLE,
    FULFILLMENT_TITLE,
    preferredContact,
    filterTabsList,
  }
}
